import { Player } from '@yleisradio/areena-types';
import { toastPlaybackError } from 'components/Notifications';
import { usePlayerState } from 'contexts/PlayerStateContext';
import { useUILanguage } from 'hooks/useUILanguage';
import Script from 'next/script';
import React, { useEffect, useRef, useState } from 'react';
import logger from 'services/logger';
import { YlePlayer as YlePlayerType } from 'types/player-static';
import { usePlayerScriptUrl } from './usePlayerScriptUrl';

type Status =
  | 'script-loading'
  | 'script-loaded'
  | 'rendering-player'
  | 'idle'
  | 'errored';

interface YlePlayerProps {
  activePlayer: Player;
  close: () => void;
  isChatAvailable: boolean;
  isChatOpen: boolean;
  openChat: () => void;
  seek: number | undefined;
  onAudioTimeUpdate: (timeInSeconds: number) => void;
  playerInstanceRef: React.MutableRefObject<YlePlayerType | undefined>;
}

export const YlePlayer: React.FunctionComponent<YlePlayerProps> = ({
  activePlayer,
  close,
  isChatAvailable,
  isChatOpen,
  openChat,
  seek,
  onAudioTimeUpdate,
  playerInstanceRef,
}) => {
  const playerScriptUrl = usePlayerScriptUrl();

  const itemId = activePlayer.item.id;
  const { type } = activePlayer.media;

  const language = useUILanguage();
  const { handleOnPlayNext, setIsPlaying, isPlaying } = usePlayerState();
  const playerElementRef = useRef<HTMLDivElement>(null);
  const isPlayerUpdatingRef = useRef(false);

  const [status, setStatus] = useState<Status>('script-loading');

  // Destroy player before unmounting
  useEffect(() => {
    return () => {
      logger.debug('Destroying player instance');
      try {
        playerInstanceRef.current?.destroy();
      } catch (e) {
        logger.warn(e, 'Error while running playerInstance.destroy');
      }
      playerInstanceRef.current = undefined;
    };
  }, [playerInstanceRef]);

  // Notify the user if the player fails to load
  useEffect(() => {
    if (status === 'errored') {
      toastPlaybackError(language);
      close();
    }
  }, [status, language, close]);

  /* YlePlayer.render is ran only once and for that reason we need to pass the
    functions as references and not as values so that they get updated with new
    props. */
  const handleOnPlayNextRef = useRef(handleOnPlayNext);
  const handleAudioOnTimeUpdateRef = useRef(onAudioTimeUpdate);

  useEffect(() => {
    handleOnPlayNextRef.current = handleOnPlayNext;
    handleAudioOnTimeUpdateRef.current = onAudioTimeUpdate;
  }, [handleOnPlayNext, onAudioTimeUpdate]);

  // Update player when program changes
  useEffect(() => {
    (async () => {
      if (!playerInstanceRef.current) return;

      logger.debug(`Changing playing program to ${itemId}`);

      try {
        isPlayerUpdatingRef.current = true;

        onAudioTimeUpdate(0);

        await playerInstanceRef.current.update({
          programId: itemId,
          autoplay: true,
        });

        isPlayerUpdatingRef.current = false;
      } catch (e) {
        logger.error(e, 'Failed to change playing program');
      }

      if (typeof seek === 'number') {
        try {
          playerInstanceRef.current.seek(seek);
        } catch (e) {
          logger.error(e, 'Failed to seek');
        }
      }
    })();
  }, [itemId, language, onAudioTimeUpdate, playerInstanceRef, seek]);

  // Keep player's chat button state up-to-date
  useEffect(() => {
    if (!playerInstanceRef.current) return;

    try {
      if (!isChatAvailable || isChatOpen) {
        playerInstanceRef.current.hideChatButton?.();
      }
      if (isChatAvailable && !isChatOpen) {
        playerInstanceRef.current.showChatButton?.();
      }
    } catch (e) {
      logger.error(e, 'Error while updating chat button state');
    }
  }, [isChatAvailable, isChatOpen, playerInstanceRef]);

  useEffect(() => {
    (async () => {
      if (!playerInstanceRef.current) return;

      // .update() calls .play() after it's done, so we need to prevent calling .play() before that
      if (isPlayerUpdatingRef.current === true) return;

      if (isPlaying) {
        try {
          await playerInstanceRef.current.play();
        } catch (e) {
          logger.error(e, 'Error while starting/resuming playback');
          toastPlaybackError(language);
        }
      } else {
        playerInstanceRef.current.pause();
      }
    })();
  }, [isPlaying, language, playerInstanceRef]);

  useEffect(() => {
    (async () => {
      try {
        if (status === 'script-loaded') {
          logger.debug(`Rendering ylePlayer for program ${itemId}`);

          if (!window.ylePlayer)
            throw new Error('window.ylePlayer not available');

          if (!playerElementRef.current)
            throw new Error('Player element not available');

          setStatus('rendering-player');

          // eslint-disable-next-line testing-library/render-result-naming-convention
          const playerInstance = await window.ylePlayer.render({
            element: playerElementRef.current,
            props: {
              aspectRatio: 'auto',
              autoplay: isPlaying,
              autoPlayNextEpisode: type === 'VideoObject',
              backButton: true,
              chatButton: false,
              chromecast: true,
              id: itemId,
              language: language === 'sv' ? 'swe' : 'fin',
              logo: false,
              playbackHistory: true,
              previewImage: false,
              ...(seek !== undefined && { seek }),
              unmuteButton: true,
              ...(type === 'AudioObject' && { yleAudioPlayer: true }),
            },
          });

          setStatus('idle');

          playerInstanceRef.current = playerInstance;

          playerInstance.on('backButtonClicked', close);
          playerInstance.on('chatButtonClicked', openChat);

          if (type === 'AudioObject') {
            playerInstance.on('onFinish', () => {
              handleOnPlayNextRef.current();
              setIsPlaying(false);
            });
            playerInstance.on('onTimeUpdate', ({ time }) => {
              handleAudioOnTimeUpdateRef.current(time);
            });
            playerInstance.on('play', ({ time }) => {
              setIsPlaying(true);
              handleAudioOnTimeUpdateRef.current(time);
            });
            playerInstance.on('pause', () => setIsPlaying(false));
          }
        }
      } catch (e) {
        logger.error(e, 'Error while rendering player');
        setStatus('errored');
      }
    })();
  }, [
    status,
    itemId,
    type,
    close,
    seek,
    language,
    openChat,
    handleOnPlayNext,
    setIsPlaying,
    playerInstanceRef,
    isPlaying,
  ]);

  return (
    <>
      <Script
        src={playerScriptUrl}
        onReady={() => setStatus('script-loaded')}
        onError={(e) => {
          logger.error(e, 'Error while loading player script');
          setStatus('errored');
        }}
      />
      <div
        ref={playerElementRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    </>
  );
};
