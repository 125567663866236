import { PlaceholderSimpleCard } from 'components/PlaceholderSimpleCard';
import { useFetchQueueCard } from 'components/Player/AudioPlayerWrapper/useFetchQueueCard';
import { UserQueueItem } from 'contexts/PlayerStateContext/UserQueueItem';
import { SimpleQueueCard } from './SimpleQueueCard/SimpleQueueCard';

type Props = {
  item: UserQueueItem;
  isSortable: boolean;
  isCheckboxSelected: boolean;
  play: () => void;
  setCheckboxSelected: (value: boolean) => void;
};

export const PlayQueueCard = ({
  isCheckboxSelected,
  isSortable,
  item,
  play,
  setCheckboxSelected,
}: Props) => {
  const cardByItemId = useFetchQueueCard(item.programId);

  if (!cardByItemId) {
    return <PlaceholderSimpleCard />;
  }

  return (
    <SimpleQueueCard
      card={cardByItemId}
      isCheckboxSelected={isCheckboxSelected}
      isSortable={isSortable}
      setCheckboxSelected={setCheckboxSelected}
      queueItem={item}
      play={play}
    />
  );
};
