import { Language } from '@yleisradio/areena-types';
import Head from 'next/head';
import React from 'react';
import { useUILanguage } from 'hooks/useUILanguage';
import { useTranslation } from 'hooks/useTranslation';
import { getCanonicalURL } from 'utils/url';
import { Query } from 'types/query';

function generateTitle(
  appTitle: string,
  viewTitle: string | undefined
): string {
  if (!viewTitle) {
    return appTitle;
  }
  if (viewTitle.includes(appTitle)) {
    return viewTitle;
  }
  return `${viewTitle} | ${appTitle}`;
}

interface Props {
  description: string | undefined;
  path: string;
  query: Query;
  robots: string | undefined;
  rssFeedUrl?: string | null;
  siteName: string;
  title: string | undefined;
  slugs?: Record<string, string> | null;
}

export const GenericMetadata: React.FunctionComponent<Props> = ({
  description,
  path,
  query,
  robots,
  rssFeedUrl,
  siteName,
  title,
  slugs,
}) => {
  const language = useUILanguage();
  const canonicalURL = path && getCanonicalURL(path, language, query, slugs);
  const alternateLanguage: Language = language === 'sv' ? 'fi' : 'sv';
  const alternateLanguageURL =
    path && getCanonicalURL(path, alternateLanguage, query);
  const t = useTranslation();

  return (
    <Head>
      <title>{generateTitle(siteName, title)}</title>
      <meta name="description" content={description || t('metaDescription')} />
      {canonicalURL ? <link rel="canonical" href={canonicalURL} /> : null}
      {canonicalURL ? (
        <link rel="alternate" hrefLang={language} href={canonicalURL} />
      ) : null}
      {alternateLanguageURL ? (
        <link
          rel="alternate"
          hrefLang={alternateLanguage}
          href={alternateLanguageURL}
        />
      ) : null}
      {rssFeedUrl && (
        <link rel="alternate" type="application/rss+xml" href={rssFeedUrl} />
      )}
      {robots ? <meta name="robots" content={robots} /> : null}
    </Head>
  );
};
