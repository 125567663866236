import {
  Card,
  CardResponse,
  Pointer,
  Notification,
} from '@yleisradio/areena-types';
import useSWR, { KeyedMutator } from 'swr';
import { getCards } from 'services/areena-api/fetchers';
import { Labels } from 'services/yleAnalyticsSdk';
import { useLocationParameters } from './useLocationParameters';
import logger from 'services/logger';
import { useTunnusContext } from 'contexts/TunnusContext';
import { useOnReceiveAnalytics } from './analytics';

interface HookReturn {
  readonly cards: Card[];
  readonly errored: boolean;
  readonly loading: boolean;
  readonly mutate: KeyedMutator<CardResponse>;
  readonly notifications: Notification[] | undefined;
  readonly totalCount: number;
  readonly analyticsContext: Labels | undefined;
}

interface Params {
  source: Pointer | undefined;
  pageIndex: number;
  pageSize: number;
  filterParameters?: Record<string, string>;
}

type FetcherParameters = [
  source: Pointer,
  offset: number,
  limit: number,
  filterParameters: Record<string, string>,
  locationParameters: Record<string, string>,
  isUserAuthenticated: boolean,
];

const fetcher = ([
  source,
  offset,
  limit,
  filterParameters,
  locationParameters,
  isUserAuthenticated,
]: FetcherParameters): Promise<CardResponse> =>
  getCards(
    source,
    offset,
    limit,
    isUserAuthenticated,
    locationParameters,
    filterParameters
  );

export function useCards({
  source,
  pageIndex,
  pageSize,
  filterParameters = {},
}: Params): HookReturn {
  const trackOnReceive = useOnReceiveAnalytics();
  const locationParameters = useLocationParameters();
  const { isAuthenticated: isUserAuthenticated } = useTunnusContext();

  const offset = pageIndex * pageSize;
  const limit = pageSize;
  const fetcherParams: FetcherParameters | null =
    source && locationParameters
      ? [
          source,
          offset,
          limit,
          filterParameters,
          locationParameters,
          isUserAuthenticated ?? false,
        ]
      : null;

  const { data, error, isLoading, mutate } = useSWR(fetcherParams, fetcher, {
    onSuccess: (data) => data && trackOnReceive(data.meta?.analytics),
  });

  if (error) {
    logger.error(
      error,
      `Error occurred while fetching ${limit} cards starting from index ${offset} for list "${source?.uri}".`
    );
  }

  return {
    cards: data?.data || [],
    errored: !!error,
    loading: !fetcherParams || isLoading,
    mutate,
    totalCount: data?.meta?.count || 0,
    notifications: data?.notifications,
    analyticsContext: data?.meta?.analytics?.context?.comscore,
  };
}
