import { Card, Control, Player } from '@yleisradio/areena-types';
import PlayIcon from 'assets/playIcon.svg';
import PauseIcon from 'assets/pauseIcon.svg';
import MessageToStudioIcon from 'assets/messageToStudio.svg';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { CardLabels } from 'components/Card/CardLabels';
import { GridContainer, GridElement } from 'components/Grid';
import { Link } from 'components/Link';
import { Time } from 'components/Time';
import { usePlayerState } from 'contexts/PlayerStateContext';
import Image from 'next/image';
import React, { ReactNode, useId, useState } from 'react';
import { filterLabelsByType, findLabel } from 'utils/card';
import { loaderUrl, squareImageLoader } from 'utils/cloudinary';
import styles from './CurrentProgram.module.scss';
import { CurrentSong } from './CurrentSong';
import { useTranslation } from 'hooks/useTranslation';
import { MessageToStudioDialogWrapper } from '../MessageToStudio/MessageToStudioDialogWrapper';

const ProgramTimes: React.FC<{ card: Card | undefined }> = ({ card }) => {
  const start = findLabel(card?.labels, 'broadcastStartDate')?.raw;
  const end = findLabel(card?.labels, 'broadcastEndDate')?.raw;

  if (start && end) {
    return (
      <>
        <Time date={start} />
        {'–'}
        <Time date={end} />
      </>
    );
  }
  return null;
};

const LinkToProgram: React.FC<{
  card: Card | undefined;
  children: ReactNode;
}> = ({ card, children }) => {
  if (card?.pointer) {
    return (
      <Link
        className={styles.link}
        dataTestId="channel-header-link-to-current-program"
        pointer={card.pointer}
      >
        {children}
      </Link>
    );
  }
  return <>{children}</>;
};

type Props = {
  card: Card | undefined;
  channelId: string;
  channelPlayer: Player | undefined;
  channelLinks: Control[] | undefined;
};

export const CurrentProgram: React.FC<Props> = ({
  card,
  channelId,
  channelPlayer,
  channelLinks,
}) => {
  const t = useTranslation();
  const { activePlayer, isPlaying, setIsPlaying, startNewSession } =
    usePlayerState();
  const titleId = useId();
  const isChannelInPlayer = activePlayer?.item.id === channelPlayer?.item.id;
  const isChannelPlaying = isChannelInPlayer && isPlaying;

  const [isMessageToStudioDialogOpen, setIsMessageToStudioDialogOpen] =
    useState(false);
  const hideMessageToStudioDialog = () => setIsMessageToStudioDialogOpen(false);

  const highlightLabels = filterLabelsByType('highlight', card?.labels);
  const messageToStudioDialogId = 'message-dialog';
  const imageElement = card?.image && (
    <Image
      alt=""
      fill
      key={card.image.id}
      priority
      src={loaderUrl(card.image)}
      loader={squareImageLoader}
      sizes="(min-width: 1920px) 11.25vw, (min-width: 1400px) 15vw, (min-width: 800px) 22.5vw, (min-width: 480px) 30vw, 45vw"
    />
  );

  return (
    <div className={classNames(!card && styles.isLoading)}>
      <GridContainer>
        <GridElement mobile={3} mobileLandscape={0} desktop={4} />

        <GridElement
          mobile={6}
          mobileLandscape={4}
          tabletLandscape={3}
          desktopSmall={6}
          desktop={4}
        >
          {card?.pointer ? (
            <Link
              className={styles.imageHolder}
              pointer={card.pointer}
              aria-labelledby={titleId}
            >
              {imageElement}
            </Link>
          ) : (
            <div className={styles.imageHolder}>{imageElement}</div>
          )}
        </GridElement>

        <GridElement mobile={3} mobileLandscape={0} />

        <GridElement
          mobile={12}
          mobileLandscape={8}
          tabletLandscape={9}
          desktopSmall={17}
          desktop={12}
        >
          <div className={styles.timeAndLabels}>
            <div className={styles.startAndEndTime}>
              <ProgramTimes card={card} />
            </div>

            {highlightLabels.length > 0 && (
              <CardLabels isHighlight labels={highlightLabels} />
            )}
          </div>

          <LinkToProgram card={card}>
            {card ? (
              <h3 className={styles.title} dir="auto" id={titleId}>
                {card.title}
              </h3>
            ) : (
              <div className={styles.title} />
            )}
            <div className={styles.description} dir="auto">
              {card?.description}
            </div>
          </LinkToProgram>

          <div className={styles.controls}>
            <Button
              text={t(isChannelPlaying ? 'pause' : 'listenToBroadcast')}
              iconLeft={isChannelPlaying ? PauseIcon : PlayIcon}
              onClick={
                isChannelPlaying
                  ? () => setIsPlaying(false)
                  : () => {
                      if (channelPlayer) {
                        if (!isChannelInPlayer) startNewSession(channelPlayer);
                        setIsPlaying(true);
                      }
                    }
              }
              disabled={!card}
            />
            <MessageToStudioDialogWrapper
              channelLinks={channelLinks}
              isMessageToStudioDialogOpen={isMessageToStudioDialogOpen}
              hideMessageToStudioDialog={hideMessageToStudioDialog}
              messageToStudioDialogId={messageToStudioDialogId}
            >
              <Button
                text={t('messageToStudio')}
                iconLeft={MessageToStudioIcon}
                onClick={() => {
                  setIsMessageToStudioDialogOpen(true);
                }}
                variant="secondary"
              />
            </MessageToStudioDialogWrapper>
          </div>
          <div className={styles.song}>
            <CurrentSong channelId={channelId} isParentLoading={!card} />
          </div>
        </GridElement>
      </GridContainer>
    </div>
  );
};
