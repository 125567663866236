import styles from './NextInRecommendationsList.module.scss';
import { PlayQueueCard } from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueueCard';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';
import { Labels } from 'services/yleAnalyticsSdk';
import { QUEUE_PAGE_SIZE, usePlayerState } from 'contexts/PlayerStateContext';
import { useTranslation } from 'hooks/useTranslation';
import { Toggle } from './Toggle';
import { QueueItem } from 'contexts/PlayerStateContext/QueueItem';
import { PlaceholderSimpleCard } from 'components/PlaceholderSimpleCard';

type Props = {
  analyticsContext: Labels | undefined;
  loading?: boolean;
  recommendationsQueue: QueueItem[];
  selectedItemKeys: string[];
  setItemSelection: (selected: boolean, key: string) => void;
  setShowRecommendationsQueue: (value: boolean) => void;
  showRecommendationsQueue: boolean;
};

const NextInRecommendationsList = ({
  analyticsContext,
  loading = false,
  recommendationsQueue,
  setItemSelection,
  selectedItemKeys,
  setShowRecommendationsQueue,
  showRecommendationsQueue,
}: Props) => {
  const { playFromRecommendationsQueue } = usePlayerState();
  const t = useTranslation();
  const toggleInputId = 'recommendations-queue-toggle';

  return (
    <AnalyticsContextProvider context={analyticsContext}>
      <div className={styles.nextInListHeader}>
        <div className={styles.headerTexts}>
          <h3 className={styles.subTitle}>
            <label htmlFor={toggleInputId}>{t('recommendationsInQueue')}</label>
          </h3>
          <div className={styles.recommendationsInfoText}>
            {t('recommendationsQueueInfoText')}
          </div>
        </div>
        <div className={styles.toggleWrapper}>
          <Toggle
            id={toggleInputId}
            checkedValue={showRecommendationsQueue}
            onCheckedChangeFunction={setShowRecommendationsQueue}
          />
        </div>
      </div>
      {showRecommendationsQueue &&
        recommendationsQueue.map((item, i) => (
          <div key={item.key} className={styles.cardContainer}>
            <PlayQueueCard
              isCheckboxSelected={selectedItemKeys.includes(item.key)}
              isSortable={false}
              item={item}
              play={() => playFromRecommendationsQueue(i)}
              setCheckboxSelected={(selected) => {
                setItemSelection(selected, item.key);
              }}
            />
          </div>
        ))}

      {loading &&
        Array.from({ length: QUEUE_PAGE_SIZE }, (_, i) => (
          <li key={i} aria-hidden>
            <PlaceholderSimpleCard />
          </li>
        ))}
    </AnalyticsContextProvider>
  );
};

export default NextInRecommendationsList;
