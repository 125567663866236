import { useCallback, useEffect, useState } from 'react';
import logger from 'services/logger';
import { UserQueueItem } from './UserQueueItem';

const LOCAL_STORAGE_QUEUE_KEY = 'podcasts:playQueue';

export function useQueueState(): [
  queue: UserQueueItem[],
  setQueue: (queue: UserQueueItem[]) => void,
] {
  const [queue, setQueueState] = useState<UserQueueItem[]>([]);

  useEffect(function loadInitialQueue() {
    setQueueState(getFromLocalStorage());
  }, []);

  const setQueue = useCallback((newQueue: UserQueueItem[]) => {
    setQueueState(newQueue);
    saveToLocalStorage(newQueue);
  }, []);

  return [queue, setQueue];
}

function getFromLocalStorage(): UserQueueItem[] {
  const item = window.localStorage.getItem(LOCAL_STORAGE_QUEUE_KEY);

  if (item) {
    try {
      const candidate: unknown = JSON.parse(item);

      if (
        Array.isArray(candidate) &&
        candidate.every((item) => typeof item == 'string')
      ) {
        return candidate.map(UserQueueItem.createFromLocalStorage);
      } else {
        logger.error('Local storage value is not an array of strings');
      }
    } catch (e) {
      logger.error(e, 'Error parsing local storage value');
    }
  }

  return [];
}

function saveToLocalStorage(queue: UserQueueItem[]): void {
  window.localStorage.setItem(
    LOCAL_STORAGE_QUEUE_KEY,
    JSON.stringify(queue.map((item) => item.programId))
  );
}
