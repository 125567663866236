import styles from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue.module.scss';
import { PlayQueueCard } from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueueCard';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';
import { usePlayerState } from 'contexts/PlayerStateContext';
import { QueueItem } from 'contexts/PlayerStateContext/QueueItem';
import { useTranslation } from 'hooks/useTranslation';
import { Labels } from 'services/yleAnalyticsSdk';

type Props = {
  seriesQueue: QueueItem[];
  analyticsContext: Labels | undefined;
  setItemSelection: (selected: boolean, key: string) => void;
  selectedItemKeys: string[];
};

const NextInSeriesList = ({
  seriesQueue,
  analyticsContext,
  setItemSelection: setItemSelection,
  selectedItemKeys,
}: Props) => {
  const { playFromSeriesQueue } = usePlayerState();
  const t = useTranslation();

  return (
    <AnalyticsContextProvider context={analyticsContext}>
      <h3 className={styles.subTitle}>{t('nextInSeries')}</h3>
      {seriesQueue.map((item, i) => {
        return (
          <div key={item.key} className={styles.cardContainer}>
            <PlayQueueCard
              item={item}
              isSortable={false}
              setCheckboxSelected={(value) => setItemSelection(value, item.key)}
              isCheckboxSelected={selectedItemKeys.includes(item.key)}
              play={() => playFromSeriesQueue(i)}
            />
          </div>
        );
      })}
    </AnalyticsContextProvider>
  );
};

export default NextInSeriesList;
