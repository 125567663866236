import styles from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue.module.scss';
import { useTranslation } from 'hooks/useTranslation';
import { PlayQueueCard } from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueueCard';
import { UserQueueItem } from 'contexts/PlayerStateContext/UserQueueItem';
import { Button } from 'components/Button';
import { usePlayerState } from 'contexts/PlayerStateContext';

type Props = {
  items: UserQueueItem[];
  selectedItemKeys: string[];
  setItemSelection: (selected: boolean, itemKey: string) => void;
  removeAllQueueItems: () => void;
};

const NextInQueueList = ({
  items,
  selectedItemKeys,
  setItemSelection,
  removeAllQueueItems,
}: Props) => {
  const { playFromQueue } = usePlayerState();
  const t = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.queueHeaderRow}>
        <h3 className={styles.playQueueSubTitle}>{t('nextInQueue')}</h3>
        <div className={styles.emptyQueueButtonWrapper}>
          <Button
            text={t('emptyQueue')}
            onClick={removeAllQueueItems}
            variant="text"
            size="xs"
            padding="none"
          />
        </div>
      </div>
      {items.map((item, i) => (
        <div key={item.key} className={styles.cardContainer}>
          <PlayQueueCard
            item={item}
            isSortable={true}
            isCheckboxSelected={selectedItemKeys.includes(item.key)}
            setCheckboxSelected={(selected) => {
              setItemSelection(selected, item.key);
            }}
            play={() => playFromQueue(i)}
          />
        </div>
      ))}
    </div>
  );
};

export default NextInQueueList;
