import { Control, Player } from '@yleisradio/areena-types';
import { useEffect, useState } from 'react';
import { useReference } from 'hooks/useReference';
import { getAvailablePlayerByPointer } from 'services/areena-api/fetchers';
import logger from 'services/logger';
import {
  isNavigator,
  isReference,
} from 'components/Controls/Control/typeGuards';

export function usePrimaryPlayer(
  primaryPlayControl: Control | undefined
): Player | null {
  const [primaryPlayer, setPrimaryPlayer] = useState<Player | null>(null);
  const reference = isReference(primaryPlayControl) ? primaryPlayControl : null;
  const finalPlayControl =
    useReference(reference).control || primaryPlayControl;

  useEffect(() => {
    if (isNavigator(finalPlayControl)) {
      getAvailablePlayerByPointer(finalPlayControl.destination).then(
        (player) => {
          if (player) {
            setPrimaryPlayer(player);
          }
        },
        (e) => {
          logger.warn(e, 'Error occurred while fetching primary player');
        }
      );
    }
  }, [finalPlayControl, setPrimaryPlayer]);

  return primaryPlayer;
}
