import React from 'react';
import styles from './MinimizedAudioPlayer.module.scss';
import dynamic from 'next/dynamic';
import { ExpandableControls } from './controls/ExpandableControls';
import { MinimizedControls } from './controls/MinimizedControls';
import { PlayerProps } from '../typesAudioPlayerWrapper';
import { useTranslation } from 'hooks/useTranslation';
import { LastPlayedSongsList } from 'components/Player/AudioPlayerWrapper/LastPlayedSongs/LastPlayedSongsList';
import { PlayQueue } from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue';
import classnames from 'classnames';

const PopoverWrapper = dynamic(() =>
  import('components/Player/AudioPlayerWrapper/PopoverWrapper').then(
    (module) => module.PopoverWrapper
  )
);

type Props = PlayerProps & {
  expandPlayer: () => void;
  isExpandable: boolean;
  isPlayerExpanded: boolean;
  progression: number;
  duration: number;
  seek: (time: number) => void;
};

export const MinimizedAudioPlayer: React.FunctionComponent<Props> = (props) => {
  const t = useTranslation();
  const playerRef = React.useRef<HTMLDivElement>(null);

  const {
    closePopover,
    expandPlayer,
    isExpandable,
    isPlayerExpanded,
    isPopoverOpen,
    isRadioChannel,
    isPlaying,
  } = props;

  return (
    <div className={styles.root} hidden={isPlayerExpanded}>
      {isExpandable ? (
        <>
          <ExpandableControls {...props} />
          <button
            className={classnames(
              styles.expandButton,
              !isPlaying && styles.withClosePlayerButton
            )}
            onClick={expandPlayer}
          >
            {t('expandPlayer')}
          </button>
        </>
      ) : (
        <MinimizedControls {...props} />
      )}
      <div ref={playerRef}>
        {isPopoverOpen && (
          <PopoverWrapper close={closePopover} isOpen={isPopoverOpen}>
            {isRadioChannel ? (
              <LastPlayedSongsList />
            ) : (
              <PlayQueue close={closePopover} />
            )}
          </PopoverWrapper>
        )}
      </div>
    </div>
  );
};
