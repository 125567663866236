import React from 'react';
import ProgressBar from 'components/Player/AudioPlayerWrapper/ProgressBar/ProgressBar';
import { CurrentlyPlayingCard } from '../CurrentlyPlayingCard';
import styles from './MinimizedControls.module.scss';
import PlayerControls from 'components/Player/AudioPlayerWrapper/PlayerControls';
import { PlayerProps } from 'components/Player/AudioPlayerWrapper/typesAudioPlayerWrapper';
import PopOverButton from 'components/Player/AudioPlayerWrapper/PopOverButton';
import MoreDropdownMenu from 'components/Player/AudioPlayerWrapper/MoreDropdownMenu';
import classNames from 'classnames';
import { getBroadcastDates } from 'utils/card';
import PlayQueueIcon from 'assets/playQueueIcon.svg';
import SongListIcon from 'assets/songListIcon.svg';
import dynamic from 'next/dynamic';
import { useTranslation } from 'hooks/useTranslation';

const Volume = dynamic(() =>
  import(
    'components/Player/AudioPlayerWrapper/MinimizedAudioPlayer/Volume'
  ).then((module) => module.Volume)
);

type MinimizedProps = PlayerProps & {
  seek: (position: number) => void;
  duration: number;
};

export const MinimizedControls = (props: MinimizedProps) => {
  const {
    progression,
    duration,
    seek,
    isPlayerExpanded,
    ongoingCard,
    upcomingCard,
    replay10,
    forward10,
    togglePopover,
    getPlaybackRates,
    getPlaybackRate,
    setPlaybackRate,
    isRadioChannel,
    isMuted,
    setMute,
    volume,
    setVolume,
  } = props;

  const { broadcastStartDate, broadcastEndDate } = getBroadcastDates(
    ongoingCard?.labels
  );

  const buttonSize = isPlayerExpanded ? 'small' : 'extra-small';

  const t = useTranslation();

  return (
    <div>
      <ProgressBar
        progression={progression}
        duration={duration}
        seek={seek}
        playerIsExpanded={isPlayerExpanded}
        broadcastStartDate={broadcastStartDate}
        broadcastEndDate={broadcastEndDate}
      />
      <div className={styles.controlsWrapper}>
        <div className={styles.controlGroup}>
          <CurrentlyPlayingCard
            ongoingCard={ongoingCard}
            upcomingCard={upcomingCard}
            isRadioChannel={isRadioChannel}
          />
        </div>

        <div
          className={classNames(
            styles.controlGroup,
            isPlayerExpanded && styles.expanded
          )}
        >
          <PlayerControls
            {...props}
            isPlayerExpanded={false}
            forward10={forward10}
            replay10={replay10}
          />
        </div>
        <div className={classNames(styles.controlGroup)}>
          <Volume
            isMuted={isMuted}
            setMute={setMute}
            setVolume={setVolume}
            volume={volume}
          />

          <PopOverButton
            size={buttonSize}
            togglePlayQueue={togglePopover}
            Icon={isRadioChannel ? SongListIcon : PlayQueueIcon}
            buttonText={isRadioChannel ? t('songs') : t('queue')}
          />

          {!isRadioChannel && (
            <MoreDropdownMenu
              getPlaybackRates={getPlaybackRates}
              getPlaybackRate={getPlaybackRate}
              setPlaybackRate={setPlaybackRate}
            />
          )}
        </div>
      </div>
    </div>
  );
};
