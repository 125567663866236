import { Language, Pointer, Service } from '@yleisradio/areena-types';
import {
  favoritesRoutes,
  guideRoutes,
  historyRoutes,
  liveRoutes,
  packageRoutes,
  personalPodcastRoutes,
  personalRoutes,
  personalTVRoutes,
  serviceRoutes,
} from './routes';
import logger from 'services/logger';
import { getCanonicalBaseURL } from './url';

export function pointerToCanonicalURL(
  pointer: Pointer,
  language: Language,
  service: Service | undefined
): string | null {
  const path = pointerToClientURL(pointer, language, service);
  const base = getCanonicalBaseURL(language);

  return path && new URL(path, base).toString();
}

export function pointerToClientURL(
  pointer: Pointer,
  language: Language,
  service: Service | undefined
): string | null {
  switch (pointer.type) {
    case 'view': {
      const { pathname: path } = new URL(pointer.uri);

      const packageViewMatch = path.match(
        /^\/v1\/ui\/views\/packages\/(30-\d+|57-[\w-]+)\.json$/
      );

      if (packageViewMatch && service) {
        const packageId = packageViewMatch[1] as string;

        return `/${serviceRoutes[service][language]}/${packageRoutes[language]}/${packageId}`;
      }

      if (path === '/v1/ui/views/live.json') {
        return `/${liveRoutes[language]}`;
      }

      const guideMatch = path.match(
        /^\/v1\/ui\/views\/(tv|radio)-guides\/\d{4}-\d{2}-\d{2}\.json$/
      );

      if (guideMatch) {
        const service = guideMatch[1] as Service;

        return `/${serviceRoutes[service][language]}/${guideRoutes[language]}`;
      }

      switch (path) {
        case '/v1/ui/views/omat.json':
          return `/${personalRoutes[language]}`;
        case '/v1/ui/views/tv-omat.json':
          return `/${personalTVRoutes[language]}`;
        case '/v1/ui/views/radio-omat.json':
          return `/${personalPodcastRoutes[language]}`;
      }

      const omatSubpageMatch = path.match(
        /^\/v1\/ui\/views\/profile\/(tv|radio)-(favorites|history)\.json$/
      );

      if (omatSubpageMatch) {
        const serviceInPointer = omatSubpageMatch[1] as Service;
        const subpage = omatSubpageMatch[2] as 'favorites' | 'history';
        const servicePathSegment = serviceRoutes[serviceInPointer][language];

        return subpage === 'favorites'
          ? `/${servicePathSegment}/${favoritesRoutes[language]}`
          : `/${servicePathSegment}/${historyRoutes[language]}`;
      }

      logger.warn(`Unknown view pointer URL. pointer.uri: ${pointer.uri}`);
      break;
    }

    case 'player': {
      break;
    }

    default: {
      if (pointer.uri.startsWith('yleareena://')) {
        return yleareenaURIToClientURL(pointer.uri, language, service);
      }

      return pointer.uri;
    }
  }

  return null;
}

function yleareenaURIToClientURL(
  uri: string,
  language: Language,
  service: Service | undefined = 'tv'
): string | null {
  const appMatch = uri.match(/^yleareena:\/\/apps\/(tv|radio)$/);

  if (appMatch) {
    const targetService = appMatch[1] as Service;

    return `/${serviceRoutes[targetService][language]}`;
  }

  const itemMatch = uri.match(/^yleareena:\/\/items\/(1-\d+)$/);

  if (itemMatch) {
    const itemId = itemMatch[1];

    return `/${itemId}`;
  }

  const packageMatch = uri.match(/^yleareena:\/\/packages\/([\w-]+)$/);

  if (packageMatch && service) {
    const prefix = `/${serviceRoutes[service][language]}/${packageRoutes[language]}`;
    const packageId = packageMatch[1] as string;

    return `${prefix}/${packageId}`;
  }

  if (uri.startsWith('yleareena://services/')) return null;

  logger.error('Invalid yleareena URI');

  return null;
}

export const idFromPointer = (pointer: Pointer | undefined): string | null => {
  if (!pointer) return null;

  const matches = pointer.uri.match(
    /^(?:yle|lasten)areena:\/\/(?:items|services|packages)\/([\w-]+)(?:\?.*)?$/
  );

  return matches?.[1] || null;
};

export function isPlayerPointer(pointer: Pointer | undefined): boolean {
  return !!pointer && pointer.type === 'player';
}
