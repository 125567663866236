import { Card, Pointer } from '@yleisradio/areena-types';
import Image from 'next/image';
import { fixedAspectRatioImageLoader, loaderUrl } from 'utils/cloudinary';
import styles from './CurrentlyPlayingCard.module.scss';
import { Link } from 'components/Link';

type Props = {
  card: Card;
  closeQueuePopover: () => void;
};

export const CurrentlyPlayingCard = ({ card, closeQueuePopover }: Props) => {
  return (
    <LinkWrapper
      className={styles.root}
      onClick={closeQueuePopover}
      pointer={card.pointer}
    >
      {card.image ? (
        <Image
          key={card.image.id}
          alt={card.image.alt || ''}
          src={loaderUrl(card.image)}
          loader={fixedAspectRatioImageLoader('1:1')}
          width={70}
          height={70}
          priority
          className={styles.image}
        />
      ) : (
        <div className={styles.image} />
      )}
      <div className={styles.textContent}>
        <div className={styles.title}>{card.title}</div>
        {card.description ? (
          <div className={styles.description}>{card.description}</div>
        ) : null}
      </div>
    </LinkWrapper>
  );
};

const LinkWrapper = ({
  children,
  className,
  onClick,
  pointer,
}: {
  children: React.ReactNode;
  className: string | undefined;
  onClick: () => void;
  pointer: Pointer | undefined;
}) =>
  pointer ? (
    <Link onClick={onClick} pointer={pointer} className={className}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );
