import { QueueItem, QueueType } from './QueueItem';

export class UserQueueItem extends QueueItem {
  private constructor(
    programId: string,
    from: UserQueueItemCreatedFrom,
    insertionIndex: number
  ) {
    super(
      programId,
      QueueType.UserQueue,
      `${Date.now()}-${insertionIndex}-${QueueType.UserQueue}-${from}`
    );
  }

  /** Can be directly used in Array.map */
  static createFromLocalStorage(programId: string, insertionIndex: number) {
    return new UserQueueItem(
      programId,
      UserQueueItemCreatedFrom.LocalStorage,
      insertionIndex
    );
  }

  /** Use this method only for adding a single program to the queue */
  static createFromOneProgram(programId: string) {
    return new UserQueueItem(programId, UserQueueItemCreatedFrom.OneProgram, 0);
  }

  /** Can be directly used in Array.map */
  static createFromQueue(queueItem: QueueItem, insertionIndex: number) {
    return new UserQueueItem(
      queueItem.programId,
      UserQueueItemCreatedFrom.Queue,
      insertionIndex
    );
  }
}

enum UserQueueItemCreatedFrom {
  LocalStorage = 'from-local-storage',
  OneProgram = 'from-program',
  Queue = 'from-queue',
}
