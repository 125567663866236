import { Control } from '@yleisradio/areena-types';
import { useCookieConsent } from 'contexts/CookieConsentContext';
import { useEffect, useRef } from 'react';
import { usePlayerState } from 'contexts/PlayerStateContext';
import { usePrimaryPlayer } from 'components/Player/usePrimaryPlayer';
import { useSeekParameter } from 'components/Player/useSeekParameter';

function isAutoplayEnabled(): boolean {
  if (typeof window !== 'undefined') {
    const { location } = window;

    return /[?#&]autoplay=true\b/.test(location.search + location.hash);
  }
  return false;
}

export function useAutoplay(primaryPlayControl: Control | undefined): void {
  const hasAutoplayBeenTriggeredRef = useRef<boolean>(false);
  const cookieConsent = useCookieConsent();
  const isCookieConsentGiven = !!cookieConsent;
  const { setIsPlaying, startNewSession } = usePlayerState();
  const primaryPlayer = usePrimaryPlayer(primaryPlayControl);
  const seek = useSeekParameter(primaryPlayer);

  useEffect(() => {
    const hasAutoplayBeenTriggered = hasAutoplayBeenTriggeredRef.current;
    if (
      (isAutoplayEnabled() || Boolean(seek)) &&
      primaryPlayer &&
      !hasAutoplayBeenTriggered &&
      isCookieConsentGiven
    ) {
      startNewSession(primaryPlayer);
      setIsPlaying(isAutoplayEnabled());
      hasAutoplayBeenTriggeredRef.current = true;
    }
  }, [
    isCookieConsentGiven,
    primaryPlayer,
    seek,
    setIsPlaying,
    startNewSession,
  ]);
}
