import { usePlayerState } from 'contexts/PlayerStateContext';
import { useUILanguage } from 'hooks/useUILanguage';
import useSWR from 'swr';
import { Language, LastPlayedSong as Song } from '@yleisradio/areena-types';
import { fetchLastPlayedSongs } from 'services/areena-api/fetchers';
import { isRadioChannel } from 'utils/item';

type RequestKey = [
  'last-played-songs',
  currentlyPlaying: string | undefined,
  language: Language,
];

const fetcher: (args: RequestKey) => Promise<Song[] | undefined> = async ([
  ,
  serviceId,
  language,
]) => {
  if (!serviceId) return;
  const response = await fetchLastPlayedSongs(serviceId, language);

  return response?.data;
};

export const useFetchLastPlayedSongs = () => {
  const { activePlayer } = usePlayerState();
  const language = useUILanguage();
  const serviceId =
    activePlayer && isRadioChannel(activePlayer.item)
      ? activePlayer.item.id
      : undefined;
  const key: RequestKey = ['last-played-songs', serviceId, language];

  const { data } = useSWR(key, fetcher, {
    refreshInterval: 300000,
  });

  return data;
};
