import { Control } from '@yleisradio/areena-types';
import React from 'react';
import PlaylistPlusIcon from 'assets/playlistPlusIcon.svg';
import PlaylistCheckIcon from 'assets/playlistCheckIcon.svg';
import { Button, ButtonPadding, ButtonSize, ButtonVariant } from './Button';
import { useTranslation } from 'hooks/useTranslation';
import { usePlayerState } from 'contexts/PlayerStateContext';
import styles from './AddToQueueButton.module.scss';
import { useTunnusContext } from 'contexts/TunnusContext';
import { idFromPointer } from 'utils/pointer';

interface Props {
  control: Control | undefined;
  onClick: React.MouseEventHandler | undefined;
  padding: ButtonPadding | undefined;
  size: ButtonSize | undefined;
  variant: ButtonVariant | undefined;
}

export const AddToQueueButton: React.FunctionComponent<Props> = ({
  control,
  onClick,
  padding,
  size,
  variant = 'secondary',
}) => {
  const t = useTranslation();
  const { isProgramInQueue } = usePlayerState();
  const { isAuthenticated } = useTunnusContext();

  const programId = idFromPointer(control?.destination);

  const text =
    isAuthenticated && programId && isProgramInQueue(programId)
      ? t('removeFromQueue')
      : t('addToQueue');

  const icon =
    isAuthenticated && programId && isProgramInQueue(programId)
      ? PlaylistCheckIcon
      : PlaylistPlusIcon;

  if (!control) return null;

  return (
    <>
      <span className={styles.mobile}>
        <Button
          text={text}
          onClick={onClick}
          padding={padding}
          size={size}
          variant={variant}
          iconLeft={icon}
          iconOnly
          dataTestId="add-to-queue-button-mobile"
        />
      </span>
      <span className={styles.desktop}>
        <Button
          text={text}
          onClick={onClick}
          padding={padding}
          size={size}
          variant={variant}
          iconLeft={icon}
          dataTestId="add-to-queue-button-desktop"
        />
      </span>
    </>
  );
};
