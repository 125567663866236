export class QueueItem {
  protected constructor(
    readonly programId: string,
    readonly queueType: QueueType,
    /** Locally unique opaque key for the queue item */
    readonly key: string
  ) {}

  static createForRecommendationsQueue(
    programId: string,
    insertionIndex: number
  ) {
    return new QueueItem(
      programId,
      QueueType.RecommendationsQueue,
      `${Date.now()}-${insertionIndex}-${QueueType.RecommendationsQueue}`
    );
  }

  static createForSeriesQueue(programId: string, insertionIndex: number) {
    return new QueueItem(
      programId,
      QueueType.SeriesQueue,
      `${Date.now()}-${insertionIndex}-${QueueType.SeriesQueue}`
    );
  }

  // For creating user queue items, use the static methods of the subclass UserQueueItem
}

export enum QueueType {
  RecommendationsQueue = 'recommendations-queue',
  SeriesQueue = 'series-queue',
  UserQueue = 'user-queue',
}
