import { Card } from '@yleisradio/areena-types';
import { sanitizeHtmlId } from 'utils/ui-helpers';
import styles from './SimpleQueueCard.module.scss';
import { useTranslation } from 'hooks/useTranslation';
import { Button } from 'components/Button';
import { useSortable } from '@dnd-kit/sortable';
import DragHandleIcon from 'assets/dragHandle.svg';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import { QueueItemData } from '../types';
import { useEffect } from 'react';
import { useEscHandler } from 'contexts/EscHandlerContext/EscHandlerContext';
import { QueueItem } from 'contexts/PlayerStateContext/QueueItem';

interface Props {
  card: Card;
  isCheckboxSelected: boolean;
  isSortable: boolean;
  play: () => void;
  queueItem: QueueItem;
  setCheckboxSelected: (value: boolean) => void;
}

export const SimpleQueueCard: React.FC<Props> = ({
  card,
  isCheckboxSelected,
  isSortable,
  play,
  queueItem,
  setCheckboxSelected,
}) => {
  const textContentId = sanitizeHtmlId(queueItem.key + 'text');

  const t = useTranslation();

  const textContent = [card.title, card.description].filter(Boolean).join(', ');

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    data: { textContent } as QueueItemData,
    disabled: !isSortable,
    id: queueItem.key,
  });

  const {
    addEventHandler: addEscHandler,
    removeEventHandler: removeEscHandler,
  } = useEscHandler();

  useEffect(() => {
    // Prevent queue dialog from closing when sorting with keyboard
    if (isDragging) {
      addEscHandler(() => {}, 'SimpleCard');
      return () => removeEscHandler('SimpleCard');
    }
  }, [addEscHandler, isDragging, removeEscHandler]);

  return (
    <div
      data-testid="card"
      aria-labelledby={textContentId}
      className={styles.container}
      ref={setNodeRef}
      role="group"
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
      }}
    >
      <label className={styles.queueCheckBoxLabel}>
        <input
          type="checkbox"
          className={styles.queueCheckBox}
          checked={isCheckboxSelected}
          onChange={(event) => {
            setCheckboxSelected(event.target.checked);
          }}
        />
        <VisuallyHidden>{t('selectQueueItem')}</VisuallyHidden>
      </label>
      <div className={styles.cardTextContentWrapper} id={textContentId}>
        <div className={styles.cardTitle}>
          <Button
            text={card.title || ''}
            onClick={play}
            variant="text"
            size="sm"
            padding="none"
            textAlign="left"
          />
        </div>
        <div className={styles.cardDescription}>{card.description}</div>
      </div>
      {isSortable ? (
        <button
          className={classNames(
            styles.handle,
            isDragging && styles.handleDragging
          )}
          ref={setActivatorNodeRef}
          {...listeners}
          {...attributes}
        >
          <DragHandleIcon className={styles.handleIcon} />
          <VisuallyHidden>{t('draggableLabel')}</VisuallyHidden>
        </button>
      ) : (
        <div className={styles.handlePlaceholder} />
      )}
    </div>
  );
};
