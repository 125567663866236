import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { useTranslation } from 'hooks/useTranslation';
import React, { useCallback, useEffect, useId, useState } from 'react';
import styles from './AudioPlayerWrapper.module.scss';
import { ExpandedAudioPlayer } from './ExpandedAudioPlayer';
import { useIsExpandedPlayerAvailable } from './hooks';
import { MinimizedAudioPlayer } from './MinimizedAudioPlayer';
import { usePlayerState } from 'contexts/PlayerStateContext';
import {
  PlayerProps,
  PlaybackRateGetter,
  PlaybackRateSetter,
  PlaybackRatesGetter,
} from './typesAudioPlayerWrapper';
import { PlayerDebugWrapper } from './PlayerDebugWrapper';
import {
  enableBodyScroll,
  disableBodyScroll,
  enableScrollPadding,
  disableScrollPadding,
} from 'components/Player/AudioPlayerWrapper/scrollHandlers';
import { useGetPlayerCards } from 'components/Player/AudioPlayerWrapper/useGetPlayerCards';
import { isRadioChannel as isRadioChannelUtil } from 'utils/item';

type Props = {
  playerElement: React.ReactElement | null;
  progression: number;
  duration: number;
  seek: (time: number) => void;
  isMuted: boolean;
  setMute: (mute: boolean) => void;
  volume: number;
  setVolume: (volume: number) => void;
  getPlaybackRates: PlaybackRatesGetter;
  getPlaybackRate: PlaybackRateGetter;
  setPlaybackRate: PlaybackRateSetter;
};

export const AudioPlayerWrapper: React.FC<Props> = ({
  playerElement,
  progression,
  duration,
  seek,
  isMuted,
  setMute,
  volume,
  setVolume,
  getPlaybackRates,
  getPlaybackRate,
  setPlaybackRate,
}) => {
  const labelId = useId();
  const isExpandedPlayerAvailable = useIsExpandedPlayerAvailable();
  const [isExpanded, setExpanded] = useState(false);
  const t = useTranslation();
  const {
    isPlaying,
    setIsPlaying,
    handleOnPlayPrev,
    handleOnPlayNext,
    hasNext,
    hasPrevious,
    activePlayer,
    isPopoverOpen,
    togglePopover,
    closePopover,
  } = usePlayerState();

  const isRadioChannel =
    !!activePlayer && isRadioChannelUtil(activePlayer.item);

  useEffect(() => {
    if (!isExpandedPlayerAvailable && isExpanded) {
      setExpanded(false);
    }
  }, [isExpanded, isExpandedPlayerAvailable]);

  const togglePlay = useCallback(
    () => setIsPlaying(!isPlaying),
    [isPlaying, setIsPlaying]
  );

  useEffect(() => {
    enableScrollPadding();

    return () => {
      disableScrollPadding();
    };
  }, []);

  useEffect(() => {
    if (isExpanded || isPopoverOpen) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }, [isExpanded, isPopoverOpen]);

  useEffect(() => {
    const keydownHandler = (event: KeyboardEvent) => {
      if (
        event.key === ' ' &&
        (event.target === document.documentElement ||
          event.target === document.body)
      ) {
        event.preventDefault();
        togglePlay();
      }
    };

    window.document.addEventListener('keydown', keydownHandler, true);

    return () =>
      window.document.removeEventListener('keydown', keydownHandler, true);
  }, [togglePlay]);

  const replay10 = useCallback(() => {
    seek(Math.max(0, progression - 10));
  }, [progression, seek]);

  const forward10 = useCallback(() => {
    seek(Math.min(duration, progression + 10));
  }, [duration, progression, seek]);

  const { ongoingCard, upcomingCard } = useGetPlayerCards();

  const playerProps: PlayerProps = {
    duration,
    seek,
    togglePlay,
    togglePopover,
    isPlaying,
    progression,
    isPlayerExpanded: isExpanded,
    replay10,
    forward10,
    previous: handleOnPlayPrev,
    next: handleOnPlayNext,
    hasNext,
    hasPrevious,
    isMuted,
    setMute,
    volume,
    setVolume,
    getPlaybackRates,
    getPlaybackRate,
    setPlaybackRate,
    isRadioChannel,
    ongoingCard,
    upcomingCard,
    isPopoverOpen,
    closePopover,
  };

  return (
    <section aria-labelledby={labelId} className={styles.root}>
      <VisuallyHidden.Root asChild>
        <h2 id={labelId}>{t('audioPlayer')}</h2>
      </VisuallyHidden.Root>

      <ExpandedAudioPlayer
        minimizePlayer={() => {
          setExpanded(false);
        }}
        {...playerProps}
      />

      <MinimizedAudioPlayer
        isExpandable={isExpandedPlayerAvailable}
        expandPlayer={() => {
          setExpanded(true);
        }}
        {...playerProps}
      />

      <PlayerDebugWrapper>{playerElement}</PlayerDebugWrapper>
    </section>
  );
};
