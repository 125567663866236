import { Analytics, Player } from '@yleisradio/areena-types';
import { toastPlaybackError } from 'components/Notifications';
import { playerToast } from 'components/Player/AudioPlayerWrapper/PlayerToast/PlayerToast';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { useShowRecommendationsQueue } from 'contexts/PlayerStateContext/useShowRecommendationsQueue';
import { useProgressCacheInvalidator } from 'contexts/ProgressCacheInvalidatorContext';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useTranslation } from 'hooks/useTranslation';
import { useUILanguage } from 'hooks/useUILanguage';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  getAvailablePlayerByProgramId,
  getCards,
} from 'services/areena-api/fetchers';
import logger from 'services/logger';
import { Labels, trackEvent } from 'services/yleAnalyticsSdk';
import { idFromPointer } from 'utils/pointer';
import { useTunnusContext } from '../TunnusContext';
import { UserQueueItem } from './UserQueueItem';
import { useQueueState } from './useQueueState';
import { useEscHandler } from 'contexts/EscHandlerContext/EscHandlerContext';
import { useLocationParameters } from 'hooks/useLocationParameters';
import { QueueItem, QueueType } from './QueueItem';
import { isRadioItem } from 'utils/item';

export const QUEUE_PAGE_SIZE = 10;
const RECOMMENDATIONS_QUEUE_MIN_LENGTH = 10;
const SERIES_QUEUE_MIN_LENGTH = 10;

// Restore podcast from local storage
// New values are JSON strings (id of the program) but old implementation stored player objects
function usePodcastToRestore(): [
  id: string | null,
  setId: (newId: string | undefined) => void,
] {
  const [podcastToRestore, setPodcastToRestore] = useLocalStorage<
    string | Player | undefined
  >('podcasts:podcastToRestore', undefined);

  let programId: string | null = null;

  try {
    if (typeof podcastToRestore === 'string') {
      programId = podcastToRestore;
    }

    // Old implementation stored player objects
    if (typeof podcastToRestore === 'object' && podcastToRestore !== null) {
      const player = podcastToRestore as Player;
      programId = player.item.id;
    }
  } catch (e) {
    logger.error('Error restoring podcast', e);
  }

  return [programId, setPodcastToRestore];
}

export type State = {
  readonly activePlayer: Player | null;
  readonly activeQueueItem: QueueItem | null;
  readonly closePlayer: () => void;
  readonly queue: UserQueueItem[];
  readonly setQueue: (queue: UserQueueItem[]) => void;
  readonly toggleProgramInQueue: (programId: string) => void;
  readonly hasNext: boolean;
  readonly hasPrevious: boolean;
  readonly handleOnPlayNext: () => void;
  readonly handleOnPlayPrev: () => void;
  readonly isProgramInQueue: (programId: string) => boolean;
  readonly isPlaying: boolean;
  readonly setIsPlaying: (isPlaying: boolean) => void;
  readonly playFromQueue: (index: number) => void;
  readonly playFromSeriesQueue: (index: number) => void;
  readonly playFromRecommendationsQueue: (index: number) => void;
  readonly startNewSession: (programIdOrPlayer: string | Player) => void;
  readonly closePopover: () => void;
  readonly isPopoverOpen: boolean;
  readonly togglePopover: () => void;
  readonly recommendationsQueue: QueueItem[];
  readonly recommendationsAnalyticsContext: Labels | undefined;
  readonly seriesQueue: QueueItem[];
  readonly seriesAnalyticsContext: Labels | undefined;
  readonly showRecommendationsQueue: boolean;
  readonly setShowRecommendationsQueue: (value: boolean) => void;
};

// Not exported on purpose, use Provider component and hook below
const PlayerStateContext = React.createContext<State>({
  activePlayer: null,
  activeQueueItem: null,
  closePlayer: () => {
    logger.warn(
      "Can't close player because ActivePlayerContext doesn't have a Provider"
    );
  },
  queue: [],
  setQueue: () => {
    logger.warn(
      "Can't set queue because ActivePlayerContext doesn't have a Provider"
    );
  },
  toggleProgramInQueue: () => {
    logger.warn(
      "Can't toggle program to queue because ActivePlayerContext doesn't have a Provider"
    );
  },
  hasNext: false,
  hasPrevious: false,
  handleOnPlayNext: () => {
    logger.warn(
      "Can't get next in queue because ActivePlayerContext doesn't have a Provider"
    );
  },
  handleOnPlayPrev: () => {
    logger.warn(
      "Can't get previous in queue because ActivePlayerContext doesn't have a Provider"
    );
  },
  isProgramInQueue: () => {
    logger.warn(
      "Can't get item in queue because ActivePlayerContext doesn't have a Provider"
    );
    return false;
  },
  isPopoverOpen: false,
  closePopover: () => {
    logger.warn(
      "Can't close popOver because ActivePlayerContext doesn't have a Provider"
    );
  },
  togglePopover: () => {
    logger.warn(
      "Can't toggle popOver becayse ActivePlayerContext doesn't have a Provider"
    );
  },
  isPlaying: false,
  playFromQueue: () => {
    logger.warn(
      "Can't play from queue because ActivePlayerContext doesn't have a Provider"
    );
  },
  playFromRecommendationsQueue: () => {
    logger.warn(
      "Can't play from recommendations queue because ActivePlayerContext doesn't have a Provider"
    );
  },
  playFromSeriesQueue: () => {
    logger.warn(
      "Can't play from series queue because ActivePlayerContext doesn't have a Provider"
    );
  },
  startNewSession: () => {
    logger.warn(
      "Can't start new session because ActivePlayerContext doesn't have a Provider"
    );
  },
  setIsPlaying() {
    logger.warn(
      "Can't set isPlaying because ActivePlayerContext doesn't have a Provider"
    );
  },
  recommendationsQueue: [],
  recommendationsAnalyticsContext: undefined,
  seriesQueue: [],
  seriesAnalyticsContext: undefined,
  showRecommendationsQueue: true,
  setShowRecommendationsQueue() {
    logger.warn(
      "Can't set show recommendations queue without Provider of the ActivePlayerContext"
    );
  },
});

export const PlayerStateProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  // Active queue item determines whether player is open or not
  const [activeQueueItem, setActiveQueueItemState] = useState<QueueItem | null>(
    null
  );
  // Don't set this directly, use setActiveQueueItem instead
  const [activePlayer, setActivePlayer] = useState<Player | null>(null);

  // The player object for which the series and recommendations queues are fetched for
  const [rootPlayer, setRootPlayer] = useState<Player | null>(null);

  const [podcastToRestore, savePodcastToRestore] = usePodcastToRestore();
  const [isPlaying, setIsPlaying] = useState(false);
  const language = useUILanguage();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [queue, setQueue] = useQueueState();

  const [recommendationsQueue, setRecommendationsQueue] = useState<QueueItem[]>(
    []
  );
  const [nextRecommendationsFetchOffset, setNextRecommendationsFetchOffset] =
    useState(0);
  const [isRecommendationsQueueDepleted, setIsRecommendationsQueueDepleted] =
    useState(false);
  const [isRecommendationsQueueLoading, setIsRecommendationsQueueLoading] =
    useState(false);
  const [recommendationsAnalyticsContext, setRecommendationsAnalyticsContext] =
    useState<Labels | undefined>();

  const [seriesQueue, setSeriesQueue] = useState<QueueItem[]>([]);
  const [nextSeriesFetchOffset, setNextSeriesFetchOffset] = useState(0);
  const [isSeriesQueueDepleted, setIsSeriesQueueDepleted] = useState(false);
  const [isSeriesQueueLoading, setIsSeriesQueueLoading] = useState(false);
  const [seriesAnalyticsContext, setSeriesAnalyticsContext] = useState<
    Labels | undefined
  >();

  // Last element is the previously played item
  const [history, setHistory] = useState<QueueItem[]>([]);

  const { invalidate: invalidateUserProgress } = useProgressCacheInvalidator();

  const { isAuthenticated } = useTunnusContext();
  const locationParameters = useLocationParameters();
  const t = useTranslation();

  const [showRecommendationsQueue, setShowRecommendationsQueue] =
    useShowRecommendationsQueue();

  const fetchPlayer = useCallback(
    async function fetchPlayer(activeQueueItem: QueueItem) {
      const player = await getAvailablePlayerByProgramId(
        activeQueueItem.programId,
        language
      );

      setActivePlayer(player);

      if (player) {
        if (isRadioItem(player.item)) {
          savePodcastToRestore(player.item.id);
        }
      } else {
        logger.error(`No available player for id ${activeQueueItem.programId}`);
        toastPlaybackError(language);
      }
    },
    [language, savePodcastToRestore]
  );

  useEffect(() => {
    async function fetchSeriesQueue() {
      if (
        rootPlayer &&
        isRadioItem(rootPlayer.item) &&
        rootPlayer.playlistSource &&
        seriesQueue.length < SERIES_QUEUE_MIN_LENGTH &&
        !isSeriesQueueDepleted &&
        !isSeriesQueueLoading &&
        isAuthenticated !== null &&
        locationParameters
      ) {
        const offset = nextSeriesFetchOffset;

        setIsSeriesQueueLoading(true);

        const { data: newSeriesCards = [] } = await getCards(
          rootPlayer.playlistSource,
          offset,
          QUEUE_PAGE_SIZE,
          isAuthenticated,
          locationParameters
        );

        setSeriesQueue((existingSeriesQueue) => [
          ...existingSeriesQueue,
          ...newSeriesCards
            .map((card) => idFromPointer(card.pointer))
            .filter((id): id is string => !!id)
            .map(QueueItem.createForSeriesQueue),
        ]);

        if (newSeriesCards.length === 0) {
          setIsSeriesQueueDepleted(true);
        }

        setIsSeriesQueueLoading(false);
        setNextSeriesFetchOffset(offset + QUEUE_PAGE_SIZE);
      }
    }

    fetchSeriesQueue().catch((e) => {
      logger.error(e, 'Error fetching series queue');
      setIsSeriesQueueDepleted(true);
      setIsSeriesQueueLoading(false);
    });
  }, [
    isAuthenticated,
    isSeriesQueueDepleted,
    isSeriesQueueLoading,
    locationParameters,
    nextSeriesFetchOffset,
    rootPlayer,
    seriesQueue.length,
  ]);

  useEffect(() => {
    async function fetchRecommendationsQueue() {
      if (!rootPlayer?.recommendationsSource) {
        setIsRecommendationsQueueDepleted(true);
        return;
      }

      if (
        isRadioItem(rootPlayer.item) &&
        recommendationsQueue.length < RECOMMENDATIONS_QUEUE_MIN_LENGTH &&
        !isRecommendationsQueueDepleted &&
        !isRecommendationsQueueLoading &&
        isAuthenticated !== null &&
        locationParameters
      ) {
        const offset = nextRecommendationsFetchOffset;

        setIsRecommendationsQueueLoading(true);

        const { data: newRecommendationCards = [] } = await getCards(
          rootPlayer.recommendationsSource,
          offset,
          QUEUE_PAGE_SIZE,
          isAuthenticated,
          locationParameters
        );

        if (newRecommendationCards.length > 0) {
          setRecommendationsQueue((existingRecommendationsQueue) => [
            ...existingRecommendationsQueue,
            ...newRecommendationCards
              .map((card) => idFromPointer(card.pointer))
              .filter((id): id is string => !!id)
              .map(QueueItem.createForRecommendationsQueue),
          ]);
        } else {
          setIsRecommendationsQueueDepleted(true);
        }

        setIsRecommendationsQueueLoading(false);
        setNextRecommendationsFetchOffset(offset + QUEUE_PAGE_SIZE);
      }
    }

    fetchRecommendationsQueue().catch((e) => {
      logger.error(e, 'Error fetching recommendations queue');
      setIsRecommendationsQueueDepleted(true);
      setIsRecommendationsQueueLoading(false);
    });
  }, [
    isAuthenticated,
    isRecommendationsQueueDepleted,
    isRecommendationsQueueLoading,
    locationParameters,
    nextRecommendationsFetchOffset,
    recommendationsQueue.length,
    rootPlayer,
  ]);

  const { addEventHandler, removeEventHandler } = useEscHandler();
  const eventHandlerTagPlayer = 'player';

  const closePlayer = useCallback(() => {
    invalidateUserProgress();
    setIsPlaying(false);
    setActiveQueueItemState(null);
    setActivePlayer(null);
    setRootPlayer(null);
    setHistory([]);
    removeEventHandler(eventHandlerTagPlayer);
    savePodcastToRestore(undefined);
  }, [invalidateUserProgress, removeEventHandler, savePodcastToRestore]);

  const setActiveQueueItem = useCallback(
    (item: QueueItem) => {
      invalidateUserProgress();
      setActiveQueueItemState(item);
      fetchPlayer(item);

      addEventHandler(() => closePlayer(), eventHandlerTagPlayer);
    },
    [addEventHandler, closePlayer, fetchPlayer, invalidateUserProgress]
  );

  const startNewSession = useCallback(
    (programIdOrPlayer: string | Player) => {
      const programId =
        typeof programIdOrPlayer === 'string'
          ? programIdOrPlayer
          : programIdOrPlayer.item.id;
      const player =
        typeof programIdOrPlayer === 'string' ? undefined : programIdOrPlayer;

      setActiveQueueItem(UserQueueItem.createFromOneProgram(programId));
      if (player) setActivePlayer(player);
      setRootPlayer(player || null);
      setHistory([]);
      setRecommendationsQueue([]);
      setNextRecommendationsFetchOffset(0);
      setIsRecommendationsQueueDepleted(false);
      setRecommendationsAnalyticsContext(undefined);
      setNextSeriesFetchOffset(0);
      setSeriesQueue([]);
      setIsSeriesQueueDepleted(false);
      setSeriesAnalyticsContext(undefined);
    },
    [setActiveQueueItem]
  );

  useEffect(() => {
    if (activePlayer && !rootPlayer) {
      startNewSession(activePlayer);
    }
  }, [activePlayer, rootPlayer, startNewSession]);

  const { areenaService } = useAreenaService();

  useEffect(() => {
    (async function restorePodcast() {
      if (areenaService === 'radio' && podcastToRestore && !activeQueueItem) {
        const player = await getAvailablePlayerByProgramId(
          podcastToRestore,
          language
        );

        if (player) {
          startNewSession(player);
        } else {
          logger.debug(
            `No available player for id ${podcastToRestore}, removing it from local storage`
          );
          savePodcastToRestore(undefined);
        }
      }
    })();
  }, [
    activeQueueItem,
    areenaService,
    language,
    podcastToRestore,
    savePodcastToRestore,
    startNewSession,
  ]);

  const eventHandlerTagPopover = 'popover';

  const closePopover = useCallback(() => {
    setIsPopoverOpen(false);
    removeEventHandler(eventHandlerTagPopover);
  }, [removeEventHandler]);

  const togglePopover = useCallback(() => {
    if (!isPopoverOpen) {
      addEventHandler(closePopover, eventHandlerTagPopover);
    } else {
      removeEventHandler(eventHandlerTagPopover);
    }
    setIsPopoverOpen((isOpen) => !isOpen);
  }, [addEventHandler, closePopover, isPopoverOpen, removeEventHandler]);

  const isProgramInQueue = useCallback(
    (id: string) => {
      return queue.some((item) => item.programId === id);
    },
    [queue]
  );

  const toggleProgramInQueue = useCallback(
    (id: string, analytics?: Analytics) => {
      if (isProgramInQueue(id)) {
        trackEvent(
          'areena.play-queue.remove',
          null,
          analytics?.context?.comscore
        );
        playerToast(t('removedFromQueue'));
        setQueue(queue.filter((item) => item.programId !== id));
      } else {
        trackEvent('areena.play-queue.add', null, analytics?.context?.comscore);
        setQueue([...queue, UserQueueItem.createFromOneProgram(id)]);
        void playerToast(t('addedToQueue'));
      }
    },
    [isProgramInQueue, queue, setQueue, t]
  );

  const hasNext =
    queue.length > 0 ||
    seriesQueue.length > 0 ||
    (recommendationsQueue.length > 0 && showRecommendationsQueue);

  const hasPrevious = history.length > 0;

  const popNextItemFromQueues = useCallback((): QueueItem | undefined => {
    if (queue[0]) {
      setQueue(queue.slice(1));
      return queue[0];
    }
    if (seriesQueue[0]) {
      setSeriesQueue(seriesQueue.slice(1));
      return seriesQueue[0];
    }
    if (recommendationsQueue[0] && showRecommendationsQueue) {
      setRecommendationsQueue(recommendationsQueue.slice(1));
      return recommendationsQueue[0];
    }
  }, [
    queue,
    recommendationsQueue,
    showRecommendationsQueue,
    seriesQueue,
    setQueue,
  ]);

  const handleOnPlayNext = useCallback(() => {
    const nextItem = popNextItemFromQueues();

    if (!nextItem) {
      throw new Error('No next item in queues');
    }

    if (activeQueueItem) {
      setHistory([...history, activeQueueItem]);
    }

    setActiveQueueItem(nextItem);
  }, [activeQueueItem, popNextItemFromQueues, history, setActiveQueueItem]);

  const handleOnPlayPrev = useCallback(() => {
    const previousItem = history.at(-1);

    if (!activeQueueItem) {
      throw new Error('No active item');
    }
    if (!previousItem) {
      throw new Error('No previous item in history');
    }

    setHistory(history.slice(0, -1));

    switch (activeQueueItem.queueType) {
      case QueueType.UserQueue:
        setQueue([activeQueueItem, ...queue]);
        break;
      case QueueType.SeriesQueue:
        setSeriesQueue((seriesQueue) => [activeQueueItem, ...seriesQueue]);
        break;
      case QueueType.RecommendationsQueue:
        setRecommendationsQueue((recommendationsQueue) => [
          activeQueueItem,
          ...recommendationsQueue,
        ]);
        break;
    }

    setActiveQueueItem(previousItem);
  }, [activeQueueItem, history, queue, setActiveQueueItem, setQueue]);

  const playFromQueue = useCallback(
    (index: number) => {
      const item = queue[index];

      if (item) {
        setActiveQueueItem(item);
        setHistory((history) => [
          ...history,
          ...(activeQueueItem ? [activeQueueItem] : []),
          ...queue.slice(0, index),
        ]);
        setQueue(queue.slice(index + 1));
      }
    },
    [activeQueueItem, queue, setActiveQueueItem, setQueue]
  );

  const playFromSeriesQueue = useCallback(
    (index: number) => {
      const item = seriesQueue[index];

      if (item) {
        setActiveQueueItem(item);
        setHistory((history) => [
          ...history,
          ...(activeQueueItem ? [activeQueueItem] : []),
          ...queue,
          ...seriesQueue.slice(0, index),
        ]);
        setQueue([]);
        setSeriesQueue(seriesQueue.slice(index + 1));
      }
    },
    [activeQueueItem, queue, seriesQueue, setActiveQueueItem, setQueue]
  );

  const playFromRecommendationsQueue = useCallback(
    (index: number) => {
      const item = recommendationsQueue[index];

      if (item) {
        setActiveQueueItem(item);
        setHistory((history) => [
          ...history,
          ...(activeQueueItem ? [activeQueueItem] : []),
          ...queue,
          ...seriesQueue,
          ...recommendationsQueue.slice(0, index),
        ]);
        setQueue([]);
        setSeriesQueue([]);
        setRecommendationsQueue(recommendationsQueue.slice(index + 1));
      }
    },
    [
      activeQueueItem,
      queue,
      recommendationsQueue,
      seriesQueue,
      setActiveQueueItem,
      setQueue,
    ]
  );

  const value = useMemo<State>(
    (): State => ({
      activePlayer,
      activeQueueItem,
      closePlayer,
      queue,
      setQueue,
      toggleProgramInQueue,
      hasNext,
      hasPrevious,
      handleOnPlayNext,
      handleOnPlayPrev,
      playFromQueue,
      playFromRecommendationsQueue,
      playFromSeriesQueue,
      startNewSession,
      isProgramInQueue,
      isPlaying,
      setIsPlaying,
      closePopover,
      isPopoverOpen,
      togglePopover,
      recommendationsQueue,
      recommendationsAnalyticsContext,
      seriesQueue,
      seriesAnalyticsContext,
      showRecommendationsQueue,
      setShowRecommendationsQueue,
    }),
    [
      activePlayer,
      activeQueueItem,
      closePlayer,
      closePopover,
      handleOnPlayNext,
      handleOnPlayPrev,
      hasNext,
      hasPrevious,
      isPlaying,
      isPopoverOpen,
      isProgramInQueue,
      playFromQueue,
      playFromRecommendationsQueue,
      playFromSeriesQueue,
      queue,
      recommendationsAnalyticsContext,
      recommendationsQueue,
      seriesAnalyticsContext,
      seriesQueue,
      setQueue,
      setShowRecommendationsQueue,
      showRecommendationsQueue,
      startNewSession,
      togglePopover,
      toggleProgramInQueue,
    ]
  );

  return (
    <PlayerStateContext.Provider value={value}>
      {children}
    </PlayerStateContext.Provider>
  );
};

export function usePlayerState(): State {
  return useContext(PlayerStateContext);
}
