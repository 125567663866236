import { Player } from '@yleisradio/areena-types';
import { useEffect, useState } from 'react';

export function useSeekParameter(
  activePlayer: Player | null
): number | undefined {
  const activePlayerItemId = activePlayer?.item.id;

  const [seek, setSeek] = useState<number | undefined>();

  useEffect(() => {
    const { search } = window.location;
    const seekParameterString = /\bseek=([1-9]\d*)\b/.exec(search)?.[1] || '';
    const newSeek = parseInt(seekParameterString, 10);
    if (!isNaN(newSeek)) {
      setSeek(newSeek);
    }
  }, [setSeek]);

  return activePlayerItemId !== undefined ? seek : undefined;
}
