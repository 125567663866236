import { Card, Language } from '@yleisradio/areena-types';
import useSWR from 'swr';
import {
  fetchRadioChannelCards,
  getCardById,
} from 'services/areena-api/fetchers';
import { useUILanguage } from 'hooks/useUILanguage';
import { usePlayerState } from 'contexts/PlayerStateContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { findLabel } from 'utils/card';
import { isRadioChannel as isRadioChannelUtil } from 'utils/item';

type RequestKey = [
  'player-card',
  itemId: string | undefined,
  language: Language,
];

const itemFetcher: (args: RequestKey) => Promise<Card | undefined> = async ([
  ,
  itemId,
  language,
]) => {
  if (!itemId) return;
  const response = await getCardById(itemId, language);

  return response?.data;
};

const channelCardsFetcher: (
  args: RequestKey
) => Promise<Card[] | undefined> = async ([, itemId, language]) => {
  if (!itemId) return;
  const response = await fetchRadioChannelCards(itemId, language, 10);

  return response?.data;
};

export const useGetPlayerCards = (): {
  ongoingCard: Card | undefined;
  upcomingCard: Card | undefined;
} => {
  const [cards, setCards] = useState<Card[] | undefined>();

  const { activePlayer } = usePlayerState();
  const language = useUILanguage();
  const timerRef = useRef<number>();

  const isRadioChannel =
    !!activePlayer && isRadioChannelUtil(activePlayer.item);

  const key: RequestKey = ['player-card', activePlayer?.item.id, language];

  const { data: itemCard } = useSWR(!isRadioChannel && key, itemFetcher);

  const { data: channelCards, mutate } = useSWR(
    isRadioChannel && key,
    channelCardsFetcher
  );

  const updateCards = useCallback(() => {
    if (cards?.length && cards.length >= 3) {
      setCards((prevState) => prevState?.slice(1));
    } else {
      void mutate();
    }
  }, [cards, mutate]);

  useEffect(() => {
    if (itemCard) {
      setCards([itemCard]);
    }
    if (channelCards) {
      setCards(channelCards);
    }
  }, [itemCard, channelCards, setCards]);

  useEffect(() => {
    if (cards?.[0] === undefined) return;

    const ongoingCardEndTime = findLabel(
      cards[0].labels,
      'broadcastEndDate'
    )?.raw;

    if (!ongoingCardEndTime) return;

    const endTime = new Date(ongoingCardEndTime).getTime();
    const now = new Date().getTime();

    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(updateCards, endTime - now);

    return () => window.clearTimeout(timerRef.current);
  }, [cards, updateCards]);

  return { ongoingCard: cards?.[0], upcomingCard: cards?.[1] };
};
