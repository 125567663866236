import { Control, Navigator, Player, Pointer } from '@yleisradio/areena-types';
import useSWR from 'swr';
import { useLocationParameters } from 'hooks/useLocationParameters';
import { getCard } from 'services/areena-api/fetchers';
import { isNavigator } from 'components/Controls/Control/typeGuards';

function isChatControl(control: Control): control is Navigator {
  return isNavigator(control) && control.tag === 'live-interaction';
}

type Key = [source: Pointer, locationParameters: Record<string, string>];
type Fetcher = (args: Key) => Promise<Navigator | undefined>;

const fetcher: Fetcher = async ([source, locationParameters]) => {
  const response = await getCard(source, locationParameters);
  const control = response?.data?.controls?.find(isChatControl);

  return control;
};

type ReturnType = {
  control: Navigator | undefined;
};

export function useChatControl(player: Player | null): ReturnType {
  const source = player?.currentProgramSource;
  const locationParameters = useLocationParameters();
  const key: Key | null =
    source && locationParameters ? [source, locationParameters] : null;

  const { data: control } = useSWR(key, fetcher);

  return { control };
}
