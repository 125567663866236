import { useCallback } from 'react';
import { Action } from './type';
import { Control } from '@yleisradio/areena-types';
import { useTunnusContext } from 'contexts/TunnusContext';
import { useLoginAction } from 'components/Controls/Control/actions/useLoginAction';
import { usePlayerState } from 'contexts/PlayerStateContext';
import logger from 'services/logger';
import { idFromPointer } from 'utils/pointer';

type Props = {
  control: Control | null;
};

export function useToggleItemInQueueAction({ control }: Props): Action {
  const { isAuthenticated } = useTunnusContext();
  const loginAction = useLoginAction();
  const { toggleProgramInQueue } = usePlayerState();

  const runAction = useCallback(() => {
    if (!isAuthenticated) {
      logger.debug('User is not logged in, item not added to play queue');
      loginAction?.runAction();
      return;
    }

    const programId = idFromPointer(control?.destination);

    if (programId) toggleProgramInQueue(programId);
  }, [isAuthenticated, loginAction, control, toggleProgramInQueue]);

  return {
    runAction,
    executing: false,
  };
}
