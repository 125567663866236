import { usePlayerState } from 'contexts/PlayerStateContext';
import { useTranslation } from 'hooks/useTranslation';
import { useFetchQueueCard } from '../useFetchQueueCard';
import styles from './PlayQueue.module.scss';
import { CurrentlyPlayingCard } from './CurrentlyPlayingCard';

type Props = {
  closeQueuePopover: () => void;
};

const CurrentlyPlaying = ({ closeQueuePopover }: Props) => {
  const t = useTranslation();
  const { activeQueueItem } = usePlayerState();
  const card = useFetchQueueCard(activeQueueItem?.programId);

  if (!activeQueueItem) return null;

  return (
    <div className={styles.container}>
      <h3 className={styles.subTitle}>{t('nowPlaying')}</h3>
      <div className={styles.cardContainer}>
        {card && (
          <CurrentlyPlayingCard
            card={card}
            closeQueuePopover={closeQueuePopover}
          />
        )}
      </div>
    </div>
  );
};

export default CurrentlyPlaying;
